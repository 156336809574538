export const fetchCountryData = async (city, locations) => {
    // Find the corresponding country based on the city
    const countryEntry = locations.find(entry => entry.city === city);
    if (!countryEntry) {
        console.error(`Country not found for city: ${city}`);
        return null;
    }

    const countryName = countryEntry.country;
    const response = await fetch(`https://restcountries.com/v3.1/name/${countryName}`);
    const data = await response.json();
    if (data && data.length > 0) {
        const country = data[0];
        return {
            flag: country.flags.svg,
            colors: country.flags.color || ['#FFFFFF']  // Default color in case it's missing
        };
    }
    return null;
};

import React, { useRef, useState, useEffect } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { FaGlobeEurope } from 'react-icons/fa';
import { RxDashboard, RxInstagramLogo, RxEnvelopeClosed } from "react-icons/rx";
import Main from './components/Main';
import LocationPage from './components/LocationPage';
import OffcanvasMenu from './components/OffcanvasMenu';
import MapboxMap from './components/MapboxMap';
import LoadingScreen from './components/LoadingScreen';
import './App.css';

const App = () => {
  const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const mapRef = useRef();
  const navigate = useNavigate();
  const location = useLocation();

  const toggleOffcanvas = () => {
    setIsOffcanvasOpen(!isOffcanvasOpen);
  };

  const handleOverviewClick = () => {
    if (mapRef.current) {
      mapRef.current.flyToOverview();
    }
    navigate('/');
  };

  useEffect(() => {
    if (loadingProgress === 100) {
      setIsMapLoaded(true);
    }
  }, [loadingProgress]);

  const showLoadingScreen = location.pathname === '/';

  return (
    <div>
      {!isMapLoaded && showLoadingScreen && <LoadingScreen progress={loadingProgress} />}
      <div className='navbar'>
        <button onClick={handleOverviewClick} className='menu-btn'>
          <FaGlobeEurope size={20} />
          <div className='tooltip'>Back to overview</div>
        </button>
        <button onClick={toggleOffcanvas} className="menu-btn">
          <RxDashboard size={20} />
          <div className='tooltip'>Places</div>
        </button>
        <a href="https://www.instagram.com/rawarck/" target='_blank' rel="noreferrer">
          <RxInstagramLogo size={20} className='instagramIcon' />
          <div className='tooltip'>Instagram</div>
        </a>
        <a href="mailto:rawarck@gmail.com">
          <RxEnvelopeClosed size={20} className='mailIcon' />
          <div className='tooltip'>Contact</div>
        </a>
      </div>
      <OffcanvasMenu isOpen={isOffcanvasOpen} onClose={toggleOffcanvas} />
      <RouteContainer mapRef={mapRef} setLoadingProgress={setLoadingProgress} />
    </div>
  );
};

const RouteContainer = ({ mapRef, setLoadingProgress }) => {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="slide"
        timeout={1000}
      >
        <Routes location={location}>
          <Route path="/" element={<MainWithMap mapRef={mapRef} setLoadingProgress={setLoadingProgress} />} />
          <Route path="/:city" element={<LocationPage />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
};

const MainWithMap = ({ mapRef, setLoadingProgress }) => {
  const location = useLocation();
  const showMapboxMap = location.pathname === '/';

  return (
    <div className="main-container">
      {showMapboxMap && <MapboxMap ref={mapRef} setLoadingProgress={setLoadingProgress} />}
      <Main />
    </div>
  );
};

export default App;

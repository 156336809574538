import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { fetchCountryData } from '../utils/countryData'; // This is your fetch function for country data
import { fetchLocations } from '../utils/fetchLocations'; // This is your fetch function for locations
import "./OffcanvasMenu.css";

const OffcanvasMenu = ({ isOpen, onClose }) => {
    const [locations, setLocations] = useState([]);
    const [otherCategories, setOtherCategories] = useState([]);  // Store "other" category items
    const [countryData, setCountryData] = useState({});  // Store country flags and colors

    useEffect(() => {
        const loadFolders = async () => {
            try {
                const response = await fetchLocations(); // Fetch the JSON containing locations and 'other' categories
                console.log("Fetched response:", response);

                // Destructure response with fallbacks to empty arrays
                const { locations = [], other = [] } = response;

                // Safeguard: Ensure locations and other are arrays
                if (!Array.isArray(locations) || !Array.isArray(other)) {
                    throw new Error("Invalid data format received.");
                }

                // Set the locations and other categories
                setLocations(locations);
                setOtherCategories(other);

                // Fetch country data for each location (country)
                const countryDataMap = {};
                for (const location of locations) {
                    const { city } = location;  // Get the city name
                    const countryInfo = await fetchCountryData(city, locations);  // Fetch country data
                    if (countryInfo) {
                        const countryKey = location.country.toLowerCase().replace(/\s+/g, '-');
                        countryDataMap[countryKey] = countryInfo;
                    }
                }

                // Set the country data for flags and colors
                setCountryData(countryDataMap);
                console.log("Fetched country data:", countryDataMap);

            } catch (error) {
                console.error('Error loading folders:', error);
            }
        };

        loadFolders();
    }, []);

    return (
        <div className={`offcanvas-menu ${isOpen ? 'open' : ''}`}>
            <button onClick={onClose} className="close-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C22 4.92893 22 7.28595 22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22ZM8.96965 8.96967C9.26254 8.67678 9.73742 8.67678 10.0303 8.96967L12 10.9394L13.9696 8.96969C14.2625 8.6768 14.7374 8.6768 15.0303 8.96969C15.3232 9.26258 15.3232 9.73746 15.0303 10.0303L13.0606 12L15.0303 13.9697C15.3232 14.2625 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2625 15.3232 13.9696 15.0303L12 13.0607L10.0303 15.0303C9.73744 15.3232 9.26256 15.3232 8.96967 15.0303C8.67678 14.7374 8.67678 14.2626 8.96965 13.9697L10.9393 12L8.96965 10.0303C8.67676 9.73744 8.67676 9.26256 8.96965 8.96967Z" fill="#ffffff" />
                </svg>
            </button>

            {/* Normal Country/City Folders */}
            <div className="portfolio_folders">
                <p>Places:</p>
                {locations.map((location, index) => {
                    const { country, city } = location;

                    // Skip entries where country or city is undefined
                    if (!country || !city) {
                        console.error(`Missing country or city in location: ${JSON.stringify(location)}`);
                        return null;
                    }

                    const countryKey = country.toLowerCase().replace(/\s+/g, '-');  // Slugify the country name
                    const countryInfo = countryData[countryKey];  // Fetch country info using the slugified country name

                    // Log the countryKey and countryInfo to debug
                    const buttonStyle = countryInfo ? { backgroundColor: countryInfo.colors[0] } : {};
                    const displayName = city.replace(/-/g, ' ');

                    return (
                        <Link to={`/${city}`} key={index} style={buttonStyle} onClick={onClose}>
                            {countryInfo && (
                                <img
                                    src={countryInfo.flag}
                                    alt={`${country} flag`}
                                    style={{ marginRight: '8px', width: '20px', height: '15px' }}
                                />
                            )}
                            {displayName}
                        </Link>
                    );
                })}
            </div>

            {/* Other Categories */}
            <div className="otherPortfolio">
                <p>Others:</p>
                {otherCategories.map((folder, index) => (
                    <Link to={`/${folder.subject}`} key={index} onClick={onClose}>
                        {folder.subject}
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default OffcanvasMenu;

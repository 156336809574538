import React, { useImperativeHandle, useRef, useEffect, forwardRef, useCallback, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useNavigate } from 'react-router-dom';
import './MapboxMap.css';

mapboxgl.accessToken = 'pk.eyJ1IjoicmF3YXJjayIsImEiOiJja280eDYyZHgxNm91Mm9zNWkxbWNtYngxIn0.I0OAJM26wcwKo24U6USCvA';

const MapboxMap = forwardRef((props, ref) => {
    const { setLoadingProgress } = props;
    const mapContainerRef = useRef(null);
    const mapRef = useRef(null);
    const navigate = useNavigate();
    const [zoomLevel, setZoomLevel] = useState(2); // Initial zoom level
    const [countries, setCountries] = useState([]); // State to hold dynamic countries data

    // Expose flyToOverview method to parent component
    useImperativeHandle(ref, () => ({
        flyToOverview() {
            if (mapRef.current) {
                mapRef.current.flyTo({
                    center: [14, 40],
                    zoom: 2,
                    essential: true,
                    speed: 1,
                    curve: 1
                });
            }
        }
    }));

    const updateProgress = useCallback((loaded, total) => {
        setLoadingProgress(Math.floor((loaded / total) * 100));
    }, [setLoadingProgress]);

    useEffect(() => {
        // Fetch the dynamic country-city data from the PHP script
        const fetchCountries = async () => {
            try {
                const response = await fetch('/api/get_locations.php'); // Adjust the path if needed
                const data = await response.json();
                console.log('Fetched countries:', data.locations); // Debugging log
                setCountries(data.locations); // Set only the `locations` array
            } catch (error) {
                console.error('Failed to fetch locations', error);
            }
        };        

        fetchCountries();
    }, []);

    useEffect(() => {
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: 'mapbox://styles/rawarck/cly49b7mk008i01pg8vbyd4rv',
            center: [14, 40],
            zoom: 2,
            minZoom: 2,
            maxZoom: 15
        });

        mapRef.current = map;

        // Update zoom level on map zoom
        map.on('zoom', () => {
            setZoomLevel(map.getZoom());
        });

        const loadMapData = async () => {
            let totalResources = countries.length;
            let loadedResources = 0;

            const incrementLoadedResources = () => {
                loadedResources += 1;
                updateProgress(loadedResources, totalResources);
            };

            const features = [];

            try {
                for (const location of countries) {
                    const response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${location.city},${location.country}.json?access_token=${mapboxgl.accessToken}`);
                    const data = await response.json();
                    if (data.features && data.features.length > 0) {
                        const coordinates = data.features[0].geometry.coordinates;
                        features.push({
                            type: 'Feature',
                            geometry: {
                                type: 'Point',
                                coordinates
                            },
                            properties: {
                                city: location.city,
                                country: location.country
                            }
                        });
                        incrementLoadedResources(); // Track each location's data load
                    }
                }
            } catch (error) {
                console.error('Error fetching location data:', error);
            }

            map.setFog({
                color: 'rgba(186, 210, 235, 0.3)',
                'high-color': 'rgba(36, 92, 223, 0.5)',
                'horizon-blend': 0.02,
                'space-color': 'rgb(11, 11, 25)',
                'star-intensity': 0.6
            });

            map.addSource('places', {
                type: 'geojson',
                data: {
                    type: 'FeatureCollection',
                    features
                },
                cluster: true,
                clusterMaxZoom: 14,
                clusterRadius: 30
            });

            map.addLayer({
                id: 'clusters',
                type: 'circle',
                source: 'places',
                filter: ['has', 'point_count'],
                paint: {
                    'circle-color': [
                        'step',
                        ['get', 'point_count'],
                        '#FE6724',
                        100,
                        '#f1f075',
                        750,
                        '#f28cb1'
                    ],
                    'circle-radius': [
                        'step',
                        ['get', 'point_count'],
                        20,
                        100,
                        30,
                        750,
                        40
                    ]
                }
            });

            map.addLayer({
                id: 'cluster-count',
                type: 'symbol',
                source: 'places',
                filter: ['has', 'point_count'],
                layout: {
                    'text-field': '{point_count_abbreviated}',
                    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                    'text-size': 12
                }
            });

            map.addLayer({
                id: 'unclustered-point',
                type: 'circle',
                source: 'places',
                filter: ['!', ['has', 'point_count']],
                paint: {
                    'circle-color': '#FE6724',
                    'circle-radius': 6,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': '#fff'
                }
            });

            let popup;
            let popupShowing = false;

            const createPopup = (coordinates, city) => {
                if (popup) {
                    const popupElement = popup.getElement();
                    if (popupElement) {
                        popupElement.classList.remove('popup-fade-in');
                        popupElement.classList.add('popup-fade-out');
                        setTimeout(() => {
                            popup.remove();
                            showPopup(coordinates, city);
                        }, 500);
                    } else {
                        showPopup(coordinates, city);
                    }
                } else {
                    showPopup(coordinates, city);
                }
            };

            const showPopup = (coordinates, city) => {
                popup = new mapboxgl.Popup({ className: 'popup-fade-in' })
                    .setLngLat(coordinates)
                    .setHTML(`<strong>${city}</strong>`)
                    .addTo(map);

                popup.on('close', () => {
                    popupShowing = false;
                });

                const popupElement = popup.getElement();
                if (popupElement) {
                    popupElement.classList.remove('popup-fade-out');
                    popupElement.classList.add('popup-fade-in');
                }
            };

            map.on('click', 'clusters', (e) => {
                const features = map.queryRenderedFeatures(e.point, {
                    layers: ['clusters']
                });
                const clusterId = features[0].properties.cluster_id;
                map.getSource('places').getClusterExpansionZoom(clusterId, (err, zoom) => {
                    if (err) return;

                    map.flyTo({
                        center: features[0].geometry.coordinates,
                        zoom
                    });
                });
            });

            map.on('click', 'unclustered-point', (e) => {
                const { city } = e.features[0].properties;
                const encodedCity = encodeURIComponent(city.replace(/ /g, '-'));
                navigate(`/${encodedCity}`);
            });

            map.on('mouseenter', 'clusters', () => {
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseenter', 'unclustered-point', (e) => {
                if (popupShowing) return;

                const coordinates = e.features[0].geometry.coordinates.slice();
                const { city } = e.features[0].properties;

                createPopup(coordinates, city);
                map.getCanvas().style.cursor = 'pointer';
            });

            map.on('mouseleave', 'unclustered-point', () => {
                if (popupShowing) return;

                if (popup) {
                    const popupElement = popup.getElement();
                    if (popupElement) {
                        popupElement.classList.remove('popup-fade-in');
                        popupElement.classList.add('popup-fade-out');
                        setTimeout(() => {
                            popup.remove();
                        }, 100);
                    }
                }

                map.getCanvas().style.cursor = '';
            });

            // Call updateProgress when map style and resources are fully loaded
            map.once('idle', () => {
                updateProgress(totalResources, totalResources);
            });
        };

        if (countries.length > 0) {
            loadMapData(); // Only load map data once countries are fetched
        }

        return () => {
            if (mapRef.current) {
                mapRef.current.remove();
            }
        };
    }, [countries, updateProgress, navigate]);

    const calculateMaskSize = () => {
        return `${1 + zoomLevel * 30 - 23}%`;
    };

    return (
        <div className="mapbox-container" ref={mapContainerRef} style={{ width: '100%', height: '100vh', position: 'relative', overflow: 'hidden' }}>
            <p className='logo' style={{ maskImage: `radial-gradient(circle, transparent ${calculateMaskSize()}, rgb(0 0 0) 84%)` }}>Hyldal</p>
        </div>
    );
});

export default MapboxMap;

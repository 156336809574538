export const fetchLocations = async () => {
    try {
        const response = await fetch('/api/get_locations.php');
        if (!response.ok) {
            throw new Error(`Error fetching locations: ${response.statusText}`);
        }
        const locations = await response.json();
        return locations;
    } catch (error) {
        console.error('Failed to fetch locations:', error);
        return []; // Return an empty array in case of error
    }
};

import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import './LocationPage.css';

const fetchPhotosByCity = async (city) => {
  try {
    const formattedCity = decodeURIComponent(city).replace(/-/g, ' ');
    const response = await fetch(`/api/get_images.php?city=${encodeURIComponent(formattedCity)}`);
    const photos = await response.json();
    return photos;
  } catch (error) {
    console.error("Failed to fetch photos", error);
    return [];
  }
};

const Modal = ({ photo, city, onClose }) => (
  <div className="modal" onClick={onClose}>
    <span className="close">&times;</span>
    <img className="modal-content" src={photo.fullSizeUrl} alt={photo.description} />
  </div>
);

const LocationPage = () => {
  const { city } = useParams();
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const imgRefs = useRef([]);

  useEffect(() => {
    const loadPhotos = async () => {
      const photosData = await fetchPhotosByCity(city);
      console.log(photosData);  // Log the response to check the data type and structure

      if (!Array.isArray(photosData)) {
        console.error("Expected an array of photos but received:", photosData);
        setLoading(false);
        return;
      }

      const sortedPhotos = photosData.sort((a, b) => {
        const numA = extractNumber(a.description);
        const numB = extractNumber(b.description);
        return numA - numB;
      });

      setPhotos(sortedPhotos);
      setLoading(false);
    };

    loadPhotos();
  }, [city]);

  const extractNumber = (description) => {
    const match = description.match(/\d+/);
    return match ? parseInt(match[0], 10) : 0;
  };

  useEffect(() => {
    if ('IntersectionObserver' in window) {
      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const img = entry.target;
            img.src = img.dataset.src;
            img.onload = () => {
              img.classList.add('loaded');
            };
            observer.unobserve(img);
          }
        });
      });
      imgRefs.current.forEach(img => {
        if (img) observer.observe(img);
      });
    } else {
      imgRefs.current.forEach(img => {
        if (img) {
          img.src = img.dataset.src;
          img.onload = () => {
            img.classList.add('loaded');
          };
        }
      });
    }
  }, [photos]);

  useEffect(() => {
    const imgWrappers = document.querySelectorAll('.imgWrapper');
    imgWrappers.forEach(wrapper => {
      const img = wrapper.querySelector('img');
      const dataSrc = img.getAttribute('data-src');
      if (dataSrc) {
        wrapper.style.setProperty('--bg-url', `url(${dataSrc})`);
      }
    });
  }, [photos]);

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const closeModal = () => {
    setSelectedPhoto(null);
  };

  return (
    <div className="location-page">
      <div className='header'>
        <a href="/"><h2 className='pageHeader'>Hyldal</h2></a>
        <h1 className='locationHeader'>{decodeURIComponent(city).replace(/-/g, ' ')}</h1>
      </div>
      <div className={`photo-gallery ${loading ? 'loading' : ''}`}>
        {photos.map((photo, index) => (
          <div className='imgWrapper' key={index}>
            <img
              ref={el => imgRefs.current[index] = el}
              data-src={photo.thumbnailUrl}
              alt={photo.description}
              className="lazyload"
              loading="lazy"
              onClick={() => handlePhotoClick(photo)}
            />
          </div>
        ))}
      </div>
      {loading && <div className="placeholder">Loading...</div>}
      {selectedPhoto && <Modal photo={selectedPhoto} city={city} onClose={closeModal} />}
    </div>
  );
};

export default LocationPage;

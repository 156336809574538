import React from 'react';
import './LoadingScreen.css';

const LoadingScreen = ({ progress }) => {
  return (
    <div className="loading-screen">
      <div className='loadingText' style={{ backgroundImage: `linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) ${progress}%, rgba(255, 255, 255, 0.15) ${progress}%, rgba(255, 255, 255, 0.15) 100%)` }}>HYLDAL</div>
      <span className='progressText'>{progress}%</span>
    </div>
  );
};

export default LoadingScreen;
